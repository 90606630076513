<template>
    <div class="page1">
        <div class="title">基本信息</div>
        <EForm :formColumns="formColumns"
               :rowSize="2"
               :optionsBtn="false"
               :actionBtn="true"
               :formData="form" ref="form1"
               :searchFlag="false"
               :labelPosition="labelPosition"
               :labelWidth="labelWidth"
               :formRules="formRules">
        </EForm>
        <shopLicenceAdd :licenceList="licenceList" :foodSafeLicenceList="foodSafeLicenceList" ref="shopLicence"></shopLicenceAdd>
        <div class="title">商户信息</div>
        <EForm :formColumns="shopInfoFormColums"
               :rowSize="2"
               :optionsBtn="false"
               :actionBtn="true"
               :formData="form" ref="form2"
               :searchFlag="false"
               :labelPosition="labelPosition"
               :labelWidth="labelWidth"
               :formRules="formRules"
               @getChange="getChange"
               @getImgList="getImgList3">
        </EForm>
        <div class="title">摊位信息</div>
        <EForm :formColumns="boothColums"
               :rowSize="2"
               :optionsBtn="false"
               :actionBtn="true"
               :formData="form" ref="form3"
               :searchFlag="false"
               :labelPosition="labelPosition"
               :labelWidth="labelWidth"
               :formRules="formRules"
               @getChange="getChange1">
        </EForm>
        <div class="title">租赁信息</div>
        <EForm :formColumns="rentColumns"
               :rowSize="2"
               :optionsBtn="false"
               :actionBtn="true"
               :formData="form" ref="form4"
               :searchFlag="false"
               :labelPosition="labelPosition"
               :labelWidth="labelWidth"
               :formRules="formRules"
               @changeRadio="changeRadio"
               @dateChange="dateChange"
        >
        </EForm>
        <ETable :tableTitle="tableTitle"
                :tableData="tableData"
                :needPagination="false"
        >
            <div slot="remark" slot-scope="scope">
                <el-input v-model="scope.data.remark"></el-input>
            </div>
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="180px"
            >
                <template slot-scope="scope">
                    <div>
                        <el-button type="text" @click="dialogShow(scope.row.orderItemList,scope.$index)"
                                   :disabled="!scope.row.orderItemList"
                        >
                            明细
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </ETable>
        <div class="tr fs15">
            合计：{{totalAmount}}元
            <el-divider></el-divider>
            <div v-for="(item,index) in orderList" :key="index">
                {{item.periods}}期交费：共{{item.feeNum}}笔，合计：{{item.totalAmount}}元
                <el-divider></el-divider>
            </div>
        </div>
        <el-row>
            <el-form label-width="140px" :rules="formRules" label-position="right">
                <el-form-item label="是否需要财务确认" required>
                    <el-radio-group v-model="form.isConfirm">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>

        </el-row>

        <el-row class="tl">

            <EButton type="primary" @click="saveData" :disabled="saveDisabled">保存</EButton>
            <el-button type="primary" @click="goBack">取消</el-button>
        </el-row>
        <div>
        </div>
        <!-- 明细弹窗 -->
        <EDialog :dialogVisible="dialogVisible" title="明细" @handleClose="cancelDialog" width="60%"
                 @handleClick="handleClick"
        >
            <ETable :tableTitle="tableTitle2"
                    :tableData="tableData2"
                    :needPagination="false"
            >
                <div slot="feeBeginDate" slot-scope="scope">
                    <el-date-picker
                            v-model="scope.data.feeBeginDate"
                            type="date"
                            placeholder="选择日期"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            style="width: 100%"
                            :picker-options="pickerOptions"
                    >
                    </el-date-picker>
                </div>
                <div slot="feeEndDate" slot-scope="scope">
                    <el-date-picker
                            v-model="scope.data.feeEndDate"
                            type="date"
                            placeholder="选择日期"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            style="width: 100%"
                            :picker-options="pickerOptionsEnd"
                    >
                    </el-date-picker>
                </div>
                <div slot="payAmount" slot-scope="scope">
                    <el-input v-model="scope.data.payAmount"
                              @blur="validate(scope.data.payAmount,()=>{
                                return scope.data.payAmount||scope.data.payAmount=='0'?true:false
                              },`金额不能为空`,()=>{scope.data.payAmount=0})"></el-input>
                </div>
                <div slot="periods" slot-scope="scope">
                    <el-input v-model="scope.data.periods"
                    ></el-input>
                </div>
            </ETable>
        </EDialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import infoList from '@/components/infoList'
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import vxRule from "@/assets/js/formValidate";
  import {otherMixin} from "@/components/mixin";
  import * as math from 'mathjs'
  import {dateFormat} from '@/assets/js/common'
  import shopLicenceAdd from './shopLicenceAdd'
  export default {
    name: 'Login',
    mixins: [otherMixin],
    data() {
      return {
        exportData: {},
        tableTitle: [
          {
            label: '收费项目名称',
            prop: 'takeReturnProjectName'
          },
          {
            label: '费用起始日期',
            prop: 'feeBeginDate'
          },
          {
            label: '费用截止日期',
            prop: 'feeEndDate'
          },
          {
            label: '金额',
            prop: 'amount',
          },
          {
            label: '单位',
            prop: 'chargeWayCn',
          },
          {
            label: '周期',
            prop: 'payTypeCn',
          },
          {
            label: '总期数',
            prop: 'periodCount',
          },
          {
            label: '总金额',
            prop: 'totalAmount',
          },
          {
            label: '备注',
            prop: 'remark',
            type: 'slot'
          }
        ],
        tableData: [],
        "orderList": [],
        dialogVisible: false,
        labelPosition: 'right',
        labelWidth: '100px',
        formColumns: [
          {
            title: '合同编号',
            property: 'contractCode',
            type: 'input',
            show: true,
            disabled:true
          },
          {
            title: '合同类型',
            property: 'contractType',
            type: 'select',
            placeholder: '请选择',
            show: true,
            options: [
              {
                label: '长租',
                value: 1
              },
              {
                label: '短暂',
                value: 2
              },
              {
                label: '临时',
                value: 3
              }
            ]
          }
        ],
        shopInfoFormColums: [
          {
            title: '商户名称',
            property: 'tenantName',
            type: 'input',
            placeholder: '商户名称',
            show: true,
            disabled:true
          },
          {
            title: '统一社会信用代码',
            property: 'socialCreditCode',
            type: 'input',
            placeholder: '请填写18位的统一社会信用代码',
            show: true,
            disabled:true
          },
          {
            title: '营业执照地址',
            property: 'businessLicenseAdress',
            type: 'input',
            placeholder: '如：XX省XX市XX县XX街道XX号',
            show: true,
            disabled:true
          },
          {
            title: '开户行及其账号',
            property: 'bankAccount',
            type: 'input',
            placeholder: '如：光大银行 XXXX XXXX XXXX XXXX',
            show: true,
            disabled:true
          },
          {
            title: '负责人姓名',
            property: 'signPerson',
            type: 'input',
            placeholder: '真实姓名',
            show: true
          },
          {
            title: '联系电话',
            property: 'phone',
            type: 'input',
            placeholder: '例如：18612148888',
            show: true
          },
          {
            title: '经营范围',
            property: 'businessScopeId',
            type: 'select',
            show: true,
            options: [],
            multiple:true,
          },
          {
            title: '身份证号码',
            property: 'idCard',
            type: 'input',
            placeholder: '例如：430582199207280544',
            show: true
          },
          {
            title: '商户类型',
            property: 'tenantType',
            type: 'select',
            placeholder: '',
            show: true,
            options: [
              {
                label: '个体',
                value: 1
              },
              {
                label: '公司',
                value: 2
              },
              {
                label: '其他',
                value: 3
              },
            ]
          },
          {
            title: '负责人籍贯',
            property: 'birthPlace',
            type: 'input',
            placeholder: '籍贯',
            show: true
          },
          {
            title: '身份证照片',
            property: 'idCardPath',
            type: 'mixinUpload',
            show: true
          },
        ],
        boothColums: [
          {
            title: '摊位号',
            property: 'boothInformationId',
            type: 'select',
            filterable: true,
            show: true,
            options: []
          },
          {
            title: '状态',
            property: 'isRent',
            type: 'value',
            show: true
          },
          {
            title: '用途',
            property: 'boothUseName',
            type: 'value',
            show: true
          },
          {
            title: '面积',
            property: 'areas',
            type: 'value',
            show: true
          },
          {
            title: '摊位类型',
            property: 'boothTypeName',
            type: 'value',
            show: true
          },
          {
            title: '区域',
            property: 'areaName',
            type: 'value',
            show: true
          },
          {
            title: '位置',
            property: 'placeName',
            type: 'value',
            show: true
          },
        ],
        rentColumns: [
          {
            title: '主营业务',
            property: 'rentPurpose',
            type: 'select',
            placeholder: '主营业务',
            options:[],
            multiple:true,
            show: true
          },
          {
            title: '生效日期',
            property: 'inDate',
            type: 'datePicker',
            show: true,
            pickerOptions: {
              disabledDate: (time) => {
                let expireDate = this.form.expireDate
                if (expireDate)
                  return time.getTime() > new Date(expireDate).getTime()
              }
            },
          },
          {
            title: '到期日期',
            property: 'expireDate',
            type: 'datePicker',
            show: true,
            pickerOptions: {
              disabledDate: (time) => {
                let inDate = this.form.inDate
                if (inDate)
                  return time.getTime() < new Date(inDate).getTime()
              }
            },
          },
          {
            title: '交费方式',
            property: 'payType',
            type: 'radio',
            show: true,
            options: [
              {
                label: '年付',
                value: 1
              },
              {
                label: '半年付',
                value: 2
              },
              {
                label: '季付',
                value: 3
              },
              {
                label: '月付',
                value: 4
              }
            ]
          },
        ],
        form: {
          originTenantId: this.$route.query.id,
          contractCode: '',//合同信息
          contractType: 1,
          tenantName: '',//商户信息
          phone: '',
          signPerson: '',
          businessScopeId: [],
          idCard: '',
          boothInformationId: '',//摊位号
          boothCode: '',
          isRent: '',
          boothUseName: '',
          areas: '',
          boothTypeName: '',
          areaName: '',
          placeName: '',
          placeId: '',//位置ID
          areaId: '',//区域ID
          tenantType: '',//租赁信息
          birthPlace: '',
          idCardPath: '',
          rentPurpose: [],
          inDate: '',
          expireDate: '',
          payType: '',
          isConfirm: 1,
          orderList: [],
          projectList: [],
          socialCreditCode:'',
          businessLicenseAdress:'',
          bankAccount:''
        },
        licenceList:[],
        foodSafeLicenceList:[],
        tableTitle2: [
          {
            label: '收费项目名称',
            prop: 'takeReturnProjectName'
          },
          {
            label: '费用起始日期',
            prop: 'feeBeginDate',
            type: 'slot'
          },
          {
            label: '费用截止日期',
            prop: 'feeEndDate',
            type: 'slot'
          },
          {
            label: '金额',
            prop: 'payAmount',
            type: 'slot'
          },
          {
            label: '期数',
            prop: 'periods',
            type: 'slot'
          },
        ],
        tableData2: [],
        formRules: {
          contractCode: vxRule(true, '', "blur", "合同编号不能为空"),
          contractType: vxRule(true, '', "change", "合同类型不能为空"),
          phone: [vxRule(true, '', "blur", "联系电话不能为空"),
            vxRule(true, val=>{
              return {result: val.toString().length==11, errMsg: "请输入11位手机号"}})],
          signPerson: vxRule(true, '', "blur", "负责人名称不能为空"),
          businessScopeId: vxRule(true, '', "change", "经营范围不能为空"),
          idCard: [vxRule(true, '', "blur", "身份证号不能为空"),
            vxRule(true, val=>{
              return {result: val.toString().length==18, errMsg: "请输入18位身份证号"}})],
          boothInformationId: vxRule(true, '', "change", "摊位号不能为空"),
          inDate: vxRule(true, '', "change", "生效日期不能为空"),
          expireDate: vxRule(true, '', "change", "到期日期不能为空"),
          payType: vxRule(true, '', "change", "交费方式不能为空"),
          isConfirm: vxRule(true, '', "change", "财务确认不能为空"),
          socialCreditCode: vxRule(true, '', "blur", "统一社会信用代码不能为空"),
          businessLicenseAdress: vxRule(true, '', "blur", "营业执照地址不能为空"),
          bankAccount: vxRule(true, '', "blur", "开户行及其账号不能为空"),
        },
        index: '',//缓存明细的index，以便后续操作
        periods: 0,//总期数 明细输入框需要做判断
        pickerOptions: {
          disabledDate: (time) => {
            let inDate = this.form.inDate
            let expireDate = this.form.expireDate
            return time.getTime() < new Date(inDate).getTime() || time.getTime() > new Date(expireDate).getTime()
          }
        },
        pickerOptionsEnd: {
          disabledDate: (time) => {
            let inDate = this.form.inDate
            let expireDate = this.form.expireDate
            return time.getTime() < new Date(inDate).getTime() || time.getTime() > new Date(expireDate).getTime()
          }
        },
        totalAmount: 0,//自己计算合计
        sourceData: [],//2月5日新增的需求，如果明细有修改过，就传isModify：1,否则：0
        sourceOrder: [],//2月5日新增的需求，如果明细有修改过，就传isModify：1,否则：0
        tableDetail:[]//缓存明细
      }
    },
    watch: {},
    created() {
        console.log(this.$route.name)
        if(this.$route.name==='contractRerent'){
            this.form.contractId=this.$route.query.contractId
            this.initRenewalData()
        }else{
            this.getBasicInfoDetail()
        }

      this.businessscopeconfigLabel()
      this.cmboothinformationLabel()
      this.dictionaryLabel()
      //初始化生效日和到期日
      let year=new Date().getFullYear()
      this.form.inDate=`${year}-07-01`
      this.form.expireDate=`${year+1}-06-30`
    },
    components: {ETable, EButton, EForm, infoList, EDialog,shopLicenceAdd},
    beforeMount() {

    },
    methods: {
        async initRenewalData(){
            let res = await Http.initRenewalData({contractId: this.form.contractId})
            if (res.code == 200) {
                let data = res.data
                //商户信息详情
                this.form.tenantName = data.tenantName
                this.form.phone = data.phone
                this.form.signPerson = data.signPerson
                let arr= data.businessScopeId.split(',')
                arr.forEach((item,index)=>{
                    arr[index]=Number(item)
                })
                this.form.businessScopeId = arr
                this.form.idCard = data.idCard
                this.form.tenantType = data.tenantType
                this.form.birthPlace = data.birthPlace
                this.form.idCardPath = data.idCardPath?data.idCardPath.split(','):[]
                this.form.socialCreditCode = data.socialCreditCode
                this.form.businessLicenseAdress = data.businessLicenseAdress
                this.form.bankAccount = data.bankAccount
            }
        },
      getChange(prop) {
        if(prop == 'tenantType'){
          if(this.form.tenantType==3){
            this.formRules={
                  contractCode: vxRule(true, '', "blur", "合同编号不能为空"),
                  contractType: vxRule(true, '', "change", "合同类型不能为空"),
                  phone: [vxRule(true, '', "blur", "联系电话不能为空"),
                vxRule(true, val=>{
                  return {result: val.toString().length==11, errMsg: "请输入11位手机号"}})],
                  signPerson: vxRule(true, '', "blur", "负责人名称不能为空"),
                  businessScopeId: vxRule(true, '', "change", "经营范围不能为空"),
                  idCard: [vxRule(true, '', "blur", "身份证号不能为空"),
                vxRule(true, val=>{
                  return {result: val.toString().length==18, errMsg: "请输入18位身份证号"}})],
                  boothInformationId: vxRule(true, '', "change", "摊位号不能为空"),
                  inDate: vxRule(true, '', "change", "生效日期不能为空"),
                  expireDate: vxRule(true, '', "change", "到期日期不能为空"),
                  payType: vxRule(true, '', "change", "交费方式不能为空"),
                  isConfirm: vxRule(true, '', "change", "财务确认不能为空"),
                  socialCreditCode: vxRule(false, '', "blur", "统一社会信用代码不能为空"),
                  businessLicenseAdress: vxRule(false, '', "blur", "营业执照地址不能为空"),
                  bankAccount: vxRule(false, '', "blur", "开户行及其账号不能为空"),
            }
          }else{
            this.formRules={
              contractCode: vxRule(true, '', "blur", "合同编号不能为空"),
              contractType: vxRule(true, '', "change", "合同类型不能为空"),
              phone: [vxRule(true, '', "blur", "联系电话不能为空"),
                vxRule(true, val=>{
                  return {result: val.toString().length==11, errMsg: "请输入11位手机号"}})],
              signPerson: vxRule(true, '', "blur", "负责人名称不能为空"),
              businessScopeId: vxRule(true, '', "change", "经营范围不能为空"),
              idCard: [vxRule(true, '', "blur", "身份证号不能为空"),
                vxRule(true, val=>{
                  return {result: val.toString().length==18, errMsg: "请输入18位身份证号"}})],
              boothInformationId: vxRule(true, '', "change", "摊位号不能为空"),
              inDate: vxRule(true, '', "change", "生效日期不能为空"),
              expireDate: vxRule(true, '', "change", "到期日期不能为空"),
              payType: vxRule(true, '', "change", "交费方式不能为空"),
              isConfirm: vxRule(true, '', "change", "财务确认不能为空"),
              socialCreditCode: vxRule(true, '', "blur", "统一社会信用代码不能为空"),
              businessLicenseAdress: vxRule(true, '', "blur", "营业执照地址不能为空"),
              bankAccount: vxRule(true, '', "blur", "开户行及其账号不能为空"),
            }
          }
        }
      },
      //获取身份证照片
      getImgList3(data) {
        this.form.idCardPath = data
      },
      //重新合计计算
      countOrder() {
        //明细列表
        let arr = []
        //一次性数据
        let timeArr = []
        this.tableData.forEach((item) => {
          if (item.orderItemList) {
            arr=[...arr,...item.orderItemList]
          }
          if (item.chargeWay == 1) {
            //arr.push(item)
          }
        })
        //console.log(JSON.stringify(arr))
        let obj = {}
        arr.forEach(item => {
          if (JSON.stringify(obj) == '{}' && item.periods) {
            obj[item.periods] = []
          }
          let keys = Object.keys(obj)
          if (item.chargeWay == 1) {
            if (keys.includes('1')) {
              obj['1'].push(item)
            } else {
              obj['1'] = []
              obj['1'].push(item)
            }
          } else {
            if (keys.includes(item.periods.toString())) {
              obj[item.periods].push(item)
            } else {
              obj[item.periods] = []
              obj[item.periods].push(item)
            }
          }

        })


        //先把数据置空
        this.orderList = []
        //开始判断
        for (let key in obj) {
          let objCash = {
            feeNum: obj[key].length,
            totalAmount: 0,
            periods: key,
            isModify:0//2021-2-5新增的需求
          }
          obj[key].forEach(item => {
            if (item.chargeWay == 1) {

              objCash.totalAmount += parseFloat(item.totalAmount)
              objCash.totalAmount = math.round(objCash.totalAmount, 2)
            } else {
              objCash.totalAmount += parseFloat(item.payAmount)
              objCash.totalAmount = math.round(objCash.totalAmount, 2)
            }

          })
          this.orderList.push(objCash)
        }
        // this.tableData.forEach((item) => {
        //   //还有一个一次性
        //   if (item.chargeWay == 1) {
        //     let keys=Object.keys(obj)
        //     if(keys.includes('1')){
        //       obj[1].push(item)
        //     }else{
        //       obj[item.periods]=[]
        //       obj[item.periods].push(item)
        //     }
        //     this.orderList[0].feeNum++
        //     this.orderList[0].totalAmount += item.totalAmount
        //   }
        // })
        //最后计算大合计
        this.totalAmount = 0
        this.orderList.forEach((item) => {
          this.totalAmount += parseFloat(item.totalAmount)
          this.totalAmount = math.round(this.totalAmount, 2)
        })

      },
      async saveContractOrder() {

        let arr = []
        this.tableData.forEach((item, index) => {
          if (item.orderItemList && item.orderItemList.length > 0) {
            item.orderItemList.forEach((sub, sindex) => {
              let data = this.sourceData[index].orderItemList[sindex]
              if (sub.payAmount != data.payAmount || sub.feeBeginDate != data.feeBeginDate || sub.feeEndDate != data.feeEndDate || sub.periods != data.periods) {
                sub.isModify = 1
                if(!arr.includes(sub.periods)){
                  arr.push(sub.periods)
                }
              }else{
                sub.isModify = 0
              }
            })
          }

        })
        this.orderList.forEach((item, index) => {
          let data = this.sourceOrder[index]
          if (!data) {
            //修改过的合计数据比原数据长，可以判断为新增的期数，所谓为修改
            item.isModify = 1
            return
          }
          if (item.totalAmount != data.totalAmount) {
            //期数相同的，金额不同，可以判定为修改
            item.isModify = 1
          }
          //如果只改了日期的
          arr.forEach((n)=>{
            if(n==item.periods){
              item.isModify=1
            }
          })
        })

        this.form.projectList = this.tableData
        this.form.orderList = this.orderList
        let photoList=[]
        //将两个证照数组push进一个数组传给后台
        this.licenceList.forEach(item => {
          if (item.validDateType == 2) {
            item.validEndDate = ''
          }
          // item.photoUrl=item.photoUrl.toString()
          photoList.push(item)
        })
        this.foodSafeLicenceList.forEach(item => {
          // item.photoUrl=item.photoUrl.toString()
          photoList.push(item)
        })
        this.form.photoList=photoList
        let form=JSON.parse(JSON.stringify(this.form))
        form.photoList.forEach((item)=>{
          item.photoUrl=item.photoUrl.toString()
        })
        form.businessScopeId=form.businessScopeId.toString()
        form.idCardPath=form.idCardPath.toString()
        form.rentPurpose=form.rentPurpose.toString()
        // this.form.businessScopeId=this.form.businessScopeId.toString()
        // this.form.rentPurpose=this.form.rentPurpose.toString()
        // this.form.idCardPath=this.form.idCardPath.toString()
        this.setDisabled(true)
        let res = await Http.saveContractOrder(form)
        if (res.code == 200) {
          this.setDisabled(false)
          this.$message.success(res.msg)
          this.goBack()
        }

      },
      //公共正则校验
      validate(data, reg, msg, fn) {
        if (!reg()) {
          this.$message.error(msg)
          fn()
        }
      },
      //摊位号下拉
      async cmboothinformationLabel() {
        let res = await Http.cmboothinformationLabel({
          isRent: 0,
          status: 1
        })
        if (res.code == 200) {
          this.boothColums[0].options = res.data
        }
      },
      async cmcontractmanageSubmit() {
        let res = await Http.cmcontractmanageSubmit(this.form)
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.goBack()
        }
      },
      handleClick() {
        //修改明细的amount会同时修改父级table的总金额
        let amount = 0
        this.tableData2.forEach((item) => {
          amount += parseFloat(item.payAmount)
        })
        this.tableData[this.index].totalAmount =  math.round(amount, 2)
        this.dialogVisible = false
        this.countOrder()

      },
      dialogShow(data, index) {
        this.dialogVisible = true
        this.tableData2 = data
        //缓存明细
        this.tableDetail=JSON.parse(JSON.stringify(data))
        this.index = index
      },
      //日期变化的时候，就要清空交费方式
      dateChange() {
        this.form.payType = ''
        this.tableData = []
      },
      //选择缴费方式
      changeRadio() {
        if (!this.form.placeId) {
          this.$message.error('请输入摊位信息')
          this.form.payType = ''
          return false;
        }
        if (!this.form.expireDate) {
          this.$message.error('请选择到期日期')
          this.form.payType = ''
          return false;
        }
        if (!this.form.inDate) {
          this.$message.error('请选择生效日期')
          this.form.payType = ''
          return false;
        }
        switch (this.form.payType) {
          case 1:
            this.periods = 1;
            break;
          case 2:
            this.periods = 2;
            break;
          case 3:
            this.periods = 4;
            break;
          case 4:
            this.periods = 12;
            break;
        }
        this.cmcontractmanageInit()

      },
      //获取摊位信息
      async getChange1() {
        let res = await Http.cmboothinformationDetail({
          boothInformationId: this.form.boothInformationId,
        })
        if (res.code == 200) {
          let data = res.data
          this.form.isRent = data.isRentCn
          this.form.boothUseName = data.boothUseName
          this.form.areas = data.areas
          this.form.boothTypeName = data.boothTypeName
          this.form.areaName = data.areaName
          this.form.placeName = data.placeName
          this.form.placeId = data.placeId
          this.form.areaId = data.areaId
          this.form.boothCode = data.boothCode
          //根据摊位号生成合同号

          let date=dateFormat.formatDate(new Date(),'yyyyMMdd')
          this.form.contractCode=`${date}${this.form.boothCode}`
        }
        // this.changeRadio()
        //每次重新选择摊位信息，都要清空租赁信息
        //初始化生效日和到期日
        let year=new Date().getFullYear()
        this.form.inDate=`${year}-07-01`
        this.form.expireDate=`${year+1}-06-30`
        this.form.payType = ''
        this.tableData = []

      },
      //签合同初始化信息
      async cmcontractmanageInit() {
        let res = await Http.cmcontractmanageInit({
          expireDate: this.form.expireDate,
          inDate: this.form.inDate,
          payType: this.form.payType,
          placeId: this.form.placeId,
          areas: this.form.areas
        })
        if (res.code == 200) {
          this.totalAmount = 0
          this.tableData = res.data.projectList
          this.sourceData = JSON.parse(JSON.stringify(this.tableData))
          this.orderList = res.data.orderList
          this.sourceOrder = JSON.parse(JSON.stringify(this.orderList))
          this.orderList.forEach((item) => {
            this.totalAmount += item.totalAmount
          })

          // this.form = res.data
        }
      },
      //获取商户详情
      async getBasicInfoDetail() {
        let res = await Http.getBasicInfoDetail({id: this.form.originTenantId})
        if (res.code == 200) {
          let data = res.data
          //商户信息详情
          this.form.tenantName = data.tenantName
          this.form.phone = data.phone
          this.form.signPerson = data.signPerson
          let arr= data.businessScopeId.split(',')
          arr.forEach((item,index)=>{
            arr[index]=Number(item)
          })
          this.form.businessScopeId = arr
          this.form.idCard = data.idCard
          this.form.tenantType = data.tenantType
          this.form.birthPlace = data.birthPlace
          this.form.idCardPath = data.idCardPath?data.idCardPath.split(','):[]
          this.form.socialCreditCode = data.socialCreditCode
          this.form.businessLicenseAdress = data.businessLicenseAdress
          this.form.bankAccount = data.bankAccount
        }
      },
      //商户经营范围下拉
      async businessscopeconfigLabel() {
        let res = await Http.businessscopeconfigLabel({
          status: 1
        })
        if (res.code == 200) {
          this.shopInfoFormColums[6].options = res.data
          this.$nextTick(()=>{
            this.shopInfoFormColums[6].multiple=true
          })

        }
      },
      //主营业务范围下拉
      async dictionaryLabel() {
        let res = await Http.dictionaryLabel({
          code: 'rentPurpose'
        })
        if (res.code == 200) {
          this.rentColumns[0].options = res.data

        }
      },
      cancelDialog() {
        this.dialogVisible = false
        console.log(this.tableDetail)
        this.tableData[this.index].orderItemList=this.tableDetail
        // this.dialogForm = {
        //   tenantCardPhotoName: '',
        // }
        // this.$refs.form.$refs.form.resetFields()
      },
      onSummit(tenantCardPhotoName) {
        this.$refs['form'].$refs['form'].validate((valid, object) => {
          if (valid) {
            this.photoInfoForm.tenantCardPhotoName = tenantCardPhotoName
            this.dialogVisible = false
            this.licenceList.push(this.photoInfoFormColumns)
          } else {
            return false;
          }
        })
      },
      saveData() {
        console.log(this.form)
        let flag = false
        this.$refs['form1'].$refs['form'].validate((valid, object) => {
          if (valid) {
            this.$refs['form2'].$refs['form'].validate((valid, object) => {
              if (valid) {
                this.$refs['form3'].$refs['form'].validate((valid, object) => {
                  if (valid) {
                    this.$refs['form4'].$refs['form'].validate((valid, object) => {
                      if (valid) {
                        flag = true
                      } else {
                        flag = false
                      }
                    })
                  } else {
                    flag = false
                  }
                })
              } else {
                flag = false
              }
            })
          } else {
            flag = false
          }
        })
        let flag1 = false
        if(this.licenceList.length>0){
          for (let i = 0; i < this.licenceList.length; i++) {
            this.$refs['shopLicence'].$refs['form2'][i].$refs['form'].validate((valid) => {
              if (valid) {
                flag1 = true
              } else {
                return false;
              }
            });
          }
        }else{
          flag1=true
        }

        let flag2 = false
        if(this.foodSafeLicenceList.length>0){
          for (let i = 0; i < this.foodSafeLicenceList.length; i++) {
            this.$refs['shopLicence'].$refs['form3'][i].$refs['form'].validate((valid) => {
              if (valid) {
                flag2 = true
              } else {
                return false;
              }
            });
          }
        }else{
          flag2=true
        }
        if (flag&&flag1&&flag2) {
          this.saveContractOrder()
        }
        // this.saveContractOrder()
      },
      //新增或者修改请求
      async getBasicInfoSubmit() {
        let res = await Http.getBasicInfoSubmit(this.form)
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.goBack()
        }
      },

    }
  }
</script>

<style lang="scss">
    .title {
        font-weight: bold;
        padding-left: 10px;
        border-left: 2px solid #4d68ff;
        margin-bottom: 20px;
    }

    .small-title {
        margin-left: 10px;
        margin-top: 20px;
    }

    .mb20 {
        margin-bottom: 20px;
    }


</style>
